<template>
    <div>
        <AppropriateSafeguards />
    </div>
</template>

<script>
import AppropriateSafeguards from '@/components/settings/appropriateSafeguards/index.vue';

export default {
    name: 'SettingsAppropriateSafeguards',
    components: {
        AppropriateSafeguards,
    },
};
</script>
